// import { Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import { Home, Dashboard, Loginpage, SignupPage, PasswordReset } from "./pages";
import { Home } from "./pages";
import { ScrollToTop } from "./components";
// import { useUserContext } from "./contexts/UserContextProvider";

function App() {
  // const { isLoggedIn } = useUserContext();

  return (
    <div className="scroll-smooth">
      <ToastContainer position="top-center text-center" />
      <Home />
      {/* <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Loginpage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route
          path="/dashboard/*"
          element={
            isLoggedIn ? <Dashboard /> : <Navigate replace to="/signup" />
          }
        />
      </Routes> */}
      <ScrollToTop />
    </div>
  );
}

export default App;
