import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import { IoCallSharp } from "react-icons/io5";

import { appSettings, contactConfig } from "../config/appConfig.js";

const Subscribe = () => {
  const [fullName, setFullName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const resetStates = () => {
    setFullName("");
    setEmailAddress("");
    setPhoneNumber("");
  };

  const sendSubscribeEmailOnSubmit = (event) => {
    event.preventDefault();

    let formValues = {
      user_name: fullName,
      user_email: emailAddress,
      user_phone: phoneNumber,
    };

    try {
      emailjs
        .send(
          appSettings.emailjsServiceId,
          appSettings.emailjsTemplateId,
          formValues,
          appSettings.emailjsPublicKey
        )
        .then(
          () => {
            toast("Message Sent Successfully ✔️");
            resetStates();
          },
          (err) => {
            toast(JSON.stringify(err));
          }
        );
    } catch (error) {
      console.log("Subscription Email Error", error);
    }
  };

  return (
    <section id="contact" className="my-gradient-1">
      <div className="container px-5 py-24 mx-auto grid grid-cols-1 lg:grid-cols-2 place-items-center gap-5 lg:gap-10">
        <div className="text-white">
          <h1 className="title-font font-medium text-3xl text-center md:text-left">
            Fastax at your service
          </h1>
          <div className="flex mt-6 justify-center md:justify-start">
            <div className="w-16 h-1 rounded-full bg-blue-100 inline-flex"></div>
          </div>
          <p className="leading-relaxed mt-4 text-center md:text-left">
            Wanna discuss further about best suitable package? Or maybe need
            help setting up Fastax?
            <br />
            We'll love to help you out. Just fill out the form or reach us
            directly.
          </p>
          <div className="mt-5 flex flex-wrap items-center justify-center md:justify-start gap-5">
            <a
              href={`tel:${contactConfig.callPhone}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="my-button-style-2 bg-blue-900 hover:bg-slate-900 w-full text-white">
                <IoCallSharp />
                <p className="ml-2">{contactConfig.callPhone}</p>
              </button>
            </a>
            <a
              href={`mailto:${contactConfig.hiEmail}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="my-button-style-2 bg-white hover:bg-blue-800 text-blue-800 hover:text-white">
                <svg
                  viewBox="0 0 24 24"
                  height="18"
                  width="18"
                  fill="none"
                  stroke="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  aria-hidden="true"
                >
                  <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                  <polyline points="22,6 12,13 2,6"></polyline>
                </svg>
                <p className="ml-2">{contactConfig.hiEmail}</p>
              </button>
            </a>
          </div>
        </div>
        <div className="bg-gray-100 w-full p-8 flex flex-col justify-center items-center rounded-lg">
          <h2 className="text-gray-900 text-xl text-center font-medium title-font mb-1">
            Request a Demo
          </h2>
          <p className="mt-2 text-center">
            Please fill out the form below & we'll get in touch shortly.
          </p>
          <div className="w-16 h-1 my-4 rounded-full bg-fastaxBlue inline-flex"></div>
          <form
            onSubmit={sendSubscribeEmailOnSubmit}
            className="w-full space-y-4"
          >
            <div className="w-full">
              <label
                htmlFor="fullName"
                className="leading-7 text-sm text-gray-600"
              >
                Full Name
              </label>
              <input
                type="text"
                name="fullName"
                required
                placeholder="Your Full Name"
                onInvalid={(e) =>
                  e.target.setCustomValidity("Enter your Full name")
                }
                onInput={(e) => e.target.setCustomValidity("")}
                onChange={(e) => setFullName(e.target.value)}
                className="w-full bg-white rounded border border-gray-300 focus:border-fastaxBlue focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="w-full">
              <label
                htmlFor="emailAddress"
                className="leading-7 text-sm text-gray-600"
              >
                Email Address
              </label>
              <input
                type="email"
                name="emailAddress"
                required
                placeholder="Your Email Address"
                onChange={(e) => setEmailAddress(e.target.value)}
                className="w-full bg-white rounded border border-gray-300 focus:border-fastaxBlue focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="w-full">
              <label
                htmlFor="phoneNumber"
                className="leading-7 text-sm text-gray-600"
              >
                Phone No.
              </label>
              <input
                type="tel"
                name="phoneNumber"
                pattern="^([0-9]+){8,}$"
                required
                placeholder="Your Contact Number"
                onInvalid={(e) =>
                  e.target.setCustomValidity("Enter Valid Phone No.")
                }
                onInput={(e) => e.target.setCustomValidity("")}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="w-full bg-white rounded border border-gray-300 focus:border-fastaxBlue focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <button
              type="submit"
              className="my-button-style-2 bg-fastaxBlue hover:bg-blue-600 text-white"
            >
              Send ⇒
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Subscribe;
