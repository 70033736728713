import React, { useState, createContext, useContext } from "react";
import { toast } from "react-toastify";

import { appSettings } from "../config/appConfig";

const SubscriptionsContext = createContext();

export const SubscriptionsContextProvider = ({ children }) => {
  const baseUrl = appSettings.serverBaseUrl;

  const [fastaxVariants, setFastaxVariants] = useState([]);

  const fetchProductVariants = (productName) => {
    fetch(`${baseUrl}/products/fetch-product-variants`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        productName,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.Error) {
          toast(response.Error + " ❗️");
        } else {
          setFastaxVariants(response);
        }
      })
      .catch((err) => {
        toast("Failed to fetch products ❗️");
      });
  };

  return (
    <SubscriptionsContext.Provider
      value={{ fastaxVariants, fetchProductVariants }}
    >
      {children}
    </SubscriptionsContext.Provider>
  );
};

export const useSubscriptionsContext = () => useContext(SubscriptionsContext);
