import React, { useState, createContext, useContext } from "react";
import { toast } from "react-toastify";

import { appSettings } from "../config/appConfig";

const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const baseUrl = appSettings.serverBaseUrl;
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentUserInfo, setCurrentUserInfo] = useState({});
  const [userProfileInfo, setUserProfileInfo] = useState({});
  // const [initiatedPasswordReset, setInitiatedPasswordReset] = useState(false);

  const loginCheck = () => {
    setIsLoading(true);
    fetch(`${baseUrl}/api/login`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setIsLoggedIn(response.isValidToken);
        setCurrentUserInfo(response);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setIsLoggedIn(false);
      });
  };

  const apiCallLogin = (email, password) => {
    setIsLoading(true);
    fetch(`${baseUrl}/api/login`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.Error) {
          toast(response.Error + " ❗️");
        } else if (response.message) {
          toast(response.message + " ✔️");
        }
        if (response.isValidToken) {
          setIsLoggedIn(true);
          setCurrentUserInfo(response);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        toast("Failed to make login request ❗️");
        setIsLoading(false);
        setIsLoggedIn(false);
      });
  };

  const apiCallLogout = () => {
    setIsLoading(true);
    fetch(`${baseUrl}/api/logout`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.isValidToken === false) {
          setIsLoggedIn(false);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoggedIn(false);
        setIsLoading(false);
      });
  };

  const apiCallRegister = (email, password, phoneNumber) => {
    setIsLoading(true);
    fetch(`${baseUrl}/api/register`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password, phoneNumber }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.Error) {
          toast(response.Error + " ❗️");
        }
        if (response.message) {
          toast(response.message + " ✔️");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        toast("Can't register user right now. Please try again later ❗️");
        setIsLoggedIn(false);
        setIsLoading(false);
      });
  };

  const apiCallFetchUserInfo = () => {
    fetch(`${baseUrl}/api/fetch-user-info`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.Error) {
          toast(response.Error + " ❗️");
        } else {
          setUserProfileInfo(response);
        }
      });
  };

  const apiCallUpdateUserInfo = (fieldName, fieldValue) => {
    fetch(`${baseUrl}/api/update-user-info`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ fieldName, fieldValue }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.Error) {
          toast(response.Error + " ❗️");
        } else if (response.message) {
          toast(response.message + " ✔️");
          apiCallFetchUserInfo();
        }
      });
  };

  // const apiCallChangePassword = (currentPassword, newPassword) => {
  //   fetch(`${baseUrl}/api/change-password`, {
  //     method: "POST",
  //     credentials: "include",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({ currentPassword, newPassword }),
  //   })
  //     .then((res) => res.json())
  //     .then((response) => {
  //       if (response.message) {
  //         toast(response.message + " ✔️");
  //       } else {
  //         toast("Unable to change password right now ❗️");
  //       }
  //     })
  //     .catch((error) => {
  //       toast(error.Error+ " ❗️");
  //     });
  // };

  // const apiCallInitiateResetPassword = (
  //   registeredUsername,
  //   registeredEmail
  // ) => {
  //   fetch(`${baseUrl}/api/initiate-reset-password`, {
  //     method: "POST",
  //     credentials: "include",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({ registeredUsername, registeredEmail }),
  //   })
  //     .then((res) => res.json())
  //     .then((response) => {
  //       if (response.message) {
  //         setInitiatedPasswordReset(true);
  //         toast(response.message + " ✔️");
  //       } else {
  //         toast(response.Error+ " ❗️");
  //       }
  //     })
  //     .catch((error) => {
  //       toast("Unable to initiate password reset ❗️");
  //     });
  // };

  // const apiCallConfirmPasswordReset = (newPassword, alphaNumericOtp) => {
  //   fetch(`${baseUrl}/api/confirm-password-reset`, {
  //     method: "POST",
  //     credentials: "include",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({ newPassword, alphaNumericOtp }),
  //   })
  //     .then((res) => res.json())
  //     .then((response) => {
  //       if (response.message) {
  //         toast(response.message + " ✔️");
  //       } else if (response.Error) {
  //         toast(response.Error + " ❗️");
  //       } else {
  //         toast("Unable to confirm password reset ❗️");
  //       }
  //     })
  //     .catch((error) => {
  //       toast(error.Error+ " ❗️");
  //     });
  // };

  // const apiCallSaveMandatoryUserDetails = (
  //   firstName,
  //   lastName,
  //   phoneNo,
  //   businessType
  // ) => {
  //   setIsLoading(true);
  //   fetch(`${baseUrl}/api/save-mandatory-user-details`, {
  //     method: "POST",
  //     credentials: "include",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       firstName,
  //       lastName,
  //       phoneNo,
  //       businessType,
  //     }),
  //   })
  //     .then((res) => res.json())
  //     .then((response) => {
  //       if (response.message) {
  //         toast(response.message, "✔️");
  //         loginCheck();
  //       } else if (response.Error) {
  //         toast(response.Error+ " ❗️");
  //       } else {
  //         toast("Unknkown Error Encountered ❗️");
  //       }
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       toast(error.Error+ " ❗️");
  //     });
  // };

  return (
    <UserContext.Provider
      value={{
        loginCheck,
        apiCallLogin,
        // apiFetchListOfUsernames,
        // apiCallChangePassword,
        // apiCallInitiateResetPassword,
        // apiCallConfirmPasswordReset,
        apiCallLogout,
        apiCallRegister,
        apiCallFetchUserInfo,
        apiCallUpdateUserInfo,
        // apiCallSaveMandatoryUserDetails,
        isLoggedIn,
        isLoading,
        currentUserInfo,
        userProfileInfo,
        // initiatedPasswordReset,
        setIsLoggedIn,
        setIsLoading,
        setCurrentUserInfo,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
