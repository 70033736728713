import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "./index.css";
import App from "./App";
import StartFromTop from "./helpers/StartFromTop";
import { UserContextProvider } from "./contexts/UserContextProvider";
import { SubscriptionsContextProvider } from "./contexts/SubscriptionsContextProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <UserContextProvider>
      <SubscriptionsContextProvider>
        <StartFromTop />
        <App />
      </SubscriptionsContextProvider>
    </UserContextProvider>
  </BrowserRouter>
);
