import React from "react";
import { useLocation } from "react-router-dom";
import { FaFacebookSquare, FaLinkedin, FaYoutube } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";

import FastaxLOGO300px from "../props/logo/FastaxLOGO300px.png";
import { contactConfig } from "../config/appConfig";

const Footer = () => {
  const currentLocation = useLocation();

  const currentYear = new Date().getFullYear();

  return (
    <footer className="container py-4 mx-auto flex flex-col md:flex-row justify-between items-center gap-y-5 text-gray-600">
      <span className="flex flex-col md:flex-row justify-between items-center gap-2">
        {currentLocation.pathname === "/" && (
          <img
            src={FastaxLOGO300px}
            alt="Fastax"
            className="h-10 md:mr-4 md:pr-4 md:border-r-2 md:border-gray-200"
          />
        )}
        <p className="text-md text-gray-500 md:py-2 md:mt-0 mt-4">
          &copy; <span>{currentYear}</span> Fastax —{" "}
          <a
            href={contactConfig.infokeyWebsite}
            target="_blank"
            rel="noopener noreferrer"
            className="text-infokeyOrange hover:animate-pulse hover:ease-in-out duration-500 hover:text-blue-800"
          >
            An Infokey Brand
          </a>
        </p>
      </span>
      <span className="flex justify-center items-center md:justify-end flex-wrap gap-5">
        <a
          href={contactConfig.facebook}
          target="_blank"
          rel="noopener noreferrer"
          className="ml-3 inline-flex items-center gap-1 text-fastaxBlue hover:text-blue-800 hover:animate-pulse hover:ease-in-out duration-500"
        >
          <FaFacebookSquare />
          <p className="font-bold">Facebook</p>
        </a>
        <a
          href={contactConfig.instagram}
          target="_blank"
          rel="noopener noreferrer"
          className="ml-3 inline-flex items-center gap-1 text-fastaxBlue hover:text-blue-800 hover:animate-pulse hover:ease-in-out duration-500"
        >
          <RiInstagramFill />
          <p className="font-bold">Instagram</p>
        </a>
        <a
          href={contactConfig.linkedin}
          target="_blank"
          rel="noopener noreferrer"
          className="ml-3 inline-flex items-center gap-1 text-fastaxBlue hover:text-blue-800 hover:animate-pulse hover:ease-in-out duration-500"
        >
          <FaLinkedin />
          <p className="font-bold">LinkedIn</p>
        </a>
        <a
          href={contactConfig.youtube}
          target="_blank"
          rel="noopener noreferrer"
          className="ml-3 inline-flex items-center gap-1 text-fastaxBlue hover:text-blue-800 hover:animate-pulse hover:ease-in-out duration-500"
        >
          <FaYoutube />
          <p className="font-bold">YouTube</p>
        </a>
      </span>
    </footer>
  );
};

export default Footer;
