import React from "react";
import { NavLink } from "react-router-dom";

import FastaxWhiteLogo from "../props/logo/Fastax-White-Logo.png";

const TempNav = () => {
  return (
    <header className="my-gradient-1 rounded-b-lg">
      <div className="container mx-auto px-4 py-4 flex flex-col md:flex-row flex-wrap justify-between items-center gap-y-5">
        <NavLink to="/">
          <img src={FastaxWhiteLogo} alt="Fastax" className="h-10" />
        </NavLink>
        <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 flex flex-wrap items-center text-lg justify-center text-blue-50">
          <a
            href="#features"
            className="my-hover-effect-underline mr-5 hover:text-white hover:font-semibold"
          >
            Features
          </a>
          <a
            href="#glimpse"
            className="my-hover-effect-underline mr-5 hover:text-white hover:font-semibold"
          >
            Glimpse
          </a>
          <a
            href="#why-fastax"
            className="my-hover-effect-underline mr-5 hover:text-white hover:font-semibold"
          >
            Why Fastax?
          </a>
          <a
            href="#fastax-in-action"
            className="my-hover-effect-underline mr-5 hover:text-white hover:font-semibold"
          >
            Fastax in Action
          </a>
          <a
            href="#about-us"
            className="my-hover-effect-underline mr-5 hover:text-white hover:font-semibold"
          >
            About Us
          </a>
          <a
            href="#pricing"
            className="my-hover-effect-underline mr-5 hover:text-white hover:font-semibold"
          >
            Pricing
          </a>
          <a
            href="#contact"
            className="my-hover-effect-underline mr-5 hover:text-white hover:font-semibold"
          >
            Contact
          </a>
        </nav>
        <a href="#contact">
          <button className="my-button-style-2 bg-white hover:bg-blue-800 text-blue-800 hover:text-white">
            Subscribe Now
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="w-4 h-4 ml-1"
              viewBox="0 0 24 24"
            >
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </button>
        </a>
      </div>
    </header>
  );
};

export default TempNav;
