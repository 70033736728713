import React from "react";

import {
  Pricing,
  Subscribe,
  TempNav,
  Footer,
  StickySocials,
} from "../components";
// import { useUserContext } from "../contexts/UserContextProvider";
import BusinessmanDoingTaxes from "../props/images/happy-businessman-doing-taxes-720px.png";
import billGeneration from "../props/feature-images/bill-generation.jpg";
import accountStatement from "../props/feature-images/account-statement.jpg";
import stockKeeping from "../props/feature-images/stock-keeping.jpg";
import gstReturn1 from "../props/feature-images/gst-return-1.jpg";
import profitAndLoss from "../props/feature-images/profit-and-loss.jpg";
import userCreation from "../props/feature-images/user-creation.jpg";
import infokeyProducts from "../props/images/infokey-products.png";
import { contactConfig } from "../config/appConfig";

const Home = () => {
  // const { isLoggedIn, loginCheck } = useUserContext();

  // useEffect(() => {
  //   loginCheck();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isLoggedIn]);

  return (
    <div className="px-5">
      <TempNav />
      <StickySocials />

      <section className="text-gray-600">
        <div className="container mx-auto px-5 py-24 flex flex-col md:flex-row items-center">
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
            <img
              className="object-cover object-center rounded"
              alt="happy-businessman-using-fastax"
              src={BusinessmanDoingTaxes}
            />
          </div>
          <div className="md:w-1/2 md:pl-16 lg:pl-24 flex flex-col md:items-start md:text-left items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
              Accounting should be <br className="hidden lg:inline-block" />{" "}
              Easy & Intuitive.
            </h1>
            <div id="short-line" className="flex my-5 justify-center">
              <div className="w-16 h-1 rounded-full bg-fastaxBlue inline-flex"></div>
            </div>
            <div className="space-y-3">
              <p className="leading-relaxed">
                Entrepreneurs usually spend more than{" "}
                <strong className="text-lg">72 hrs/month</strong> just managing
                their accounts, generating bills & doing taxes.{" "}
                <br className="hidden lg:inline-block" /> Opportunity Cost of
                not simplifying this can be more than{" "}
                <strong className="text-lg">₹30 Lakhs</strong>
              </p>
              <h2 className="text-xl">But no need to worry!</h2>
              <p className="text-3xl">
                <strong id="fastaxText">Fastax</strong> to the rescue.
              </p>
              <p>
                We make your Financial Accounting easy, so that you can focus
                more on growing your Business and not just staring at Excel
                Sheets.
              </p>
            </div>
            <a href="#features" className="mt-5">
              <button className="my-button-style-2 bg-fastaxBlue hover:bg-blue-600 w-full text-white">
                Tell me More!
              </button>
            </a>
          </div>
        </div>
      </section>

      <section id="features" className="my-gradient-1">
        <div className="container px-5 py-24 mx-auto">
          <div className="text-white text-center mb-20">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4">
              We ease the boring stuff, <br className="hidden sm:block" />
              so you enjoy doing Business.
            </h1>
            <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-white">
              We simplify everything from financial accounting to generating
              reports, <br className="hidden sm:block" />
              inventory management and much more.
            </p>
            <div className="flex mt-6 justify-center">
              <div className="w-16 h-1 rounded-full bg-blue-100 inline-flex"></div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
            <div className="bg-gray-100 w-full p-3 flex rounded-lg">
              <div className="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
                <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-fastaxBlue text-white mb-4 flex-shrink-0">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                  </svg>
                </div>
                <div className="flex-grow">
                  <h2 className="text-gray-900 text-lg title-font font-medium">
                    Easy Invoicing
                  </h2>
                  <p className="leading-relaxed text-base">
                    Generate, Print, Email and WhatsApp invoices quickly to your
                    customers in real time.
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-gray-100 w-full p-3 flex rounded-lg">
              <div className="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
                <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-fastaxBlue text-white mb-4 flex-shrink-0">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                  </svg>
                </div>
                <div className="flex-grow">
                  <h2 className="text-gray-900 text-lg title-font font-medium">
                    Sales Management
                  </h2>
                  <p className="leading-relaxed text-base">
                    Management processes made easy for all types of businesses.
                    Be it Sales, Sales Return, Sales Register, analyzing Monthly
                    sales or generating HSN wise reports.
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-gray-100 w-full p-3 flex rounded-lg">
              <div className="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
                <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-fastaxBlue text-white mb-4 flex-shrink-0">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                  </svg>
                </div>
                <div className="flex-grow">
                  <h2 className="text-gray-900 text-lg title-font font-medium">
                    Purchase Management
                  </h2>
                  <p className="leading-relaxed text-base">
                    Similar interface like Sales makes it intuitive to navigate
                    around. Manage Purchase Return, Purchase Register, analyze
                    Monthly Purchase and generate HSN wise reports.
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-gray-100 w-full p-3 flex rounded-lg">
              <div className="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
                <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-fastaxBlue text-white mb-4 flex-shrink-0">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                  </svg>
                </div>
                <div className="flex-grow">
                  <h2 className="text-gray-900 text-lg title-font font-medium">
                    Stock Management
                  </h2>
                  <p className="leading-relaxed text-base">
                    Get Stock in Hand, Weekly Stock and Item Ledger to easily
                    maintain and segregate your products.
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-gray-100 w-full p-3 flex rounded-lg">
              <div className="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
                <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-fastaxBlue text-white mb-4 flex-shrink-0">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                  </svg>
                </div>
                <div className="flex-grow">
                  <h2 className="text-gray-900 text-lg title-font font-medium">
                    GST Reports
                  </h2>
                  <p className="leading-relaxed text-base">
                    Generate GST reports like GSTR-1, GSTR-2, GSTR-3B and pay
                    your taxes from the App itself.
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-gray-100 w-full p-3 flex rounded-lg">
              <div className="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
                <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-fastaxBlue text-white mb-4 flex-shrink-0">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                  </svg>
                </div>
                <div className="flex-grow">
                  <h2 className="text-gray-900 text-lg title-font font-medium">
                    Setting up Accounts
                  </h2>
                  <p className="leading-relaxed text-base">
                    Fastax's simplistic approach makes creating, adding and
                    editing Account Heads, Supplier Accounts, Customer Accounts
                    & Stock Items easily.
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-gray-100 w-full p-3 flex rounded-lg">
              <div className="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
                <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-fastaxBlue text-white mb-4 flex-shrink-0">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                  </svg>
                </div>
                <div className="flex-grow">
                  <h2 className="text-gray-900 text-lg title-font font-medium">
                    API Integration
                  </h2>
                  <p className="leading-relaxed text-base">
                    View company's health, get Banking integration, GST check,
                    e-way Billing, Yatter-WhatsApp messaging and more with
                    Fastax's smart API integration.
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-gray-100 w-full p-3 flex rounded-lg">
              <div className="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
                <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-fastaxBlue text-white mb-4 flex-shrink-0">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                  </svg>
                </div>
                <div className="flex-grow">
                  <h2 className="text-gray-900 text-lg title-font font-medium">
                    Financial Accounting
                  </h2>
                  <p className="leading-relaxed text-base">
                    Maintain Accounts Statement, manage Receivables & Payables
                    create Daybook & Trial Balance, check Profit & Loss and all
                    the core Financial Accounting features.
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-gray-100 w-full p-3 flex rounded-lg">
              <div className="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
                <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-fastaxBlue text-white mb-4 flex-shrink-0">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                  </svg>
                </div>
                <div className="flex-grow">
                  <h2 className="text-gray-900 text-lg title-font font-medium">
                    Business Analytics
                  </h2>
                  <p className="leading-relaxed text-base">
                    Get all the sales, purchase, cash balance, bank balance and
                    item movement graphs to visualize your Account Health.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="glimpse" className="text-gray-600">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-wrap w-full mb-20">
            <div className="lg:w-1/2 w-full mb-6 lg:mb-0">
              <h1 className="mb-2 text-3xl md:text-2xl font-medium title-font text-gray-900">
                Have a look yourself, <br className="hidden sm:block" />
                how <strong id="fastaxText">Fastax</strong> makes your life
                easy.
              </h1>
              <div className="w-16 h-1 rounded-full bg-fastaxBlue inline-flex"></div>
            </div>
            <p className="w-full lg:w-1/2 leading-relaxed text-gray-500 text-center md:text-left">
              Fastax streamlines your financial management by easing bill
              generation, tracking account statements, managing stock,
              generating PnL statements, allowing user management and so much
              more. Get more time to focus on your business growth and let
              Fastax simplify your accounting tasks.
            </p>
          </div>
          <div className="flex flex-wrap -m-4">
            <div className="md:w-1/2 p-4">
              <div className="bg-gray-100 p-4 rounded-lg">
                <img
                  className="h-64 rounded w-full object-cover object-center mb-6"
                  src={billGeneration}
                  alt="content"
                />
                <h3 className="tracking-widest text-fastaxBlue text-xs font-medium uppercase title-font">
                  Generate Invoices Quickly
                  <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
                    Bill Generation
                  </h2>
                </h3>
                <p className="leading-relaxed text-base">
                  Generate sales and purchase bills & invoices in PDF or print
                  with ease using Fastax's intuitive interface.
                </p>
              </div>
            </div>
            <div className="md:w-1/2 p-4">
              <div className="bg-gray-100 p-4 rounded-lg">
                <img
                  className="h-64 rounded w-full object-cover object-center mb-6"
                  src={accountStatement}
                  alt="content"
                />
                <h3 className="tracking-widest text-fastaxBlue text-xs font-medium uppercase title-font">
                  No need for calculator
                </h3>
                <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
                  Account Statement
                </h2>
                <p className="leading-relaxed text-base">
                  Ledger or Account Statement serves as a repository for all
                  financial data from other subledgers and modules.
                </p>
              </div>
            </div>
            <div className="md:w-1/2 p-4">
              <div className="bg-gray-100 p-4 rounded-lg">
                <img
                  className="h-64 rounded w-full object-cover object-center mb-6"
                  src={stockKeeping}
                  alt="content"
                />
                <h3 className="tracking-widest text-fastaxBlue text-xs font-medium uppercase title-font">
                  1, 2, 3, 4 ... Stop counting
                </h3>
                <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
                  Stock Keeping
                </h2>
                <p className="leading-relaxed text-base">
                  Manage and keep track of your inventory levels and stock
                  movements by getting real-time updates with Fastax's Stock
                  keeping feature.
                </p>
              </div>
            </div>
            <div className="md:w-1/2 p-4">
              <div className="bg-gray-100 p-4 rounded-lg">
                <img
                  className="h-64 rounded w-full object-cover object-center mb-6"
                  src={gstReturn1}
                  alt="content"
                />
                <h3 className="tracking-widest text-fastaxBlue text-xs font-medium uppercase title-font">
                  SGST, CGST and IGST
                </h3>
                <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
                  GST Returns
                </h2>
                <p className="leading-relaxed text-base">
                  Save your time and effort by easing the process of filing your
                  GST returns and ensure that you are always compliant with the
                  latest GST regulations.
                </p>
              </div>
            </div>
            <div className="md:w-1/2 p-4">
              <div className="bg-gray-100 p-4 rounded-lg">
                <img
                  className="h-64 rounded w-full object-cover object-center mb-6"
                  src={profitAndLoss}
                  alt="content"
                />
                <h3 className="tracking-widest text-fastaxBlue text-xs font-medium uppercase title-font">
                  How Green is my account?
                </h3>
                <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
                  Profit And Loss
                </h2>
                <p className="leading-relaxed text-base">
                  Track your income and expenses to ease your understanding of
                  your business's financial performance and make informed
                  decisions about it's future.
                </p>
              </div>
            </div>
            <div className="md:w-1/2 p-4">
              <div className="bg-gray-100 p-4 rounded-lg">
                <img
                  className="h-64 rounded w-full object-cover object-center mb-6"
                  src={userCreation}
                  alt="content"
                />
                <h3 className="tracking-widest text-fastaxBlue text-xs font-medium uppercase title-font">
                  He manages bills, She manages accounts
                </h3>
                <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
                  User Management
                </h2>
                <p className="leading-relaxed text-base">
                  Create multiple user profiles to manage access to Fastax and
                  have control over who has access to your financial data and
                  what they can do with it.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="why-fastax" className="my-gradient-1">
        <div className="container px-5 py-24 mx-auto space-y-3">
          <div className="text-white text-center mb-20">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4">
              Why choose Fastax, <br className="hidden sm:block" />
              you ask?
            </h1>
            <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-500s">
              Grab some popcorn coz there are a lot of reasons.
            </p>
            <div className="flex mt-6 justify-center">
              <div className="w-16 h-1 rounded-full bg-blue-100 inline-flex"></div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5">
            <div className="bg-gray-100 w-full p-8 flex gap-5 rounded-lg">
              <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-fastaxBlue text-white mb-4 flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-6 h-6"
                  viewBox="0 0 24 24"
                >
                  <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                </svg>
              </div>
              <div className="flex-grow">
                <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                  No need to learn Fastax. <br />
                  It's all intuitive.
                </h2>
                <p className="leading-relaxed text-base">
                  In a sea of user unfriendly softwares where people have to
                  learn how to operate a software, Fastax is easy & intuitive to
                  operate with very small learning curve.
                </p>
              </div>
            </div>
            <div className="bg-gray-100 w-full p-8 flex gap-5 rounded-lg">
              <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-fastaxBlue text-white mb-4 flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-6 h-6"
                  viewBox="0 0 24 24"
                >
                  <circle cx="6" cy="6" r="3"></circle>
                  <circle cx="6" cy="18" r="3"></circle>
                  <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
                </svg>
              </div>
              <div className="flex-grow">
                <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                  Your Data is Private. <br />
                  Always.
                </h2>
                <p className="leading-relaxed text-base">
                  All your data is saved locally on your device and backed-up
                  with state-of-the-art tools & technologies to maintain Data
                  Privacy and Integrity.
                </p>
              </div>
            </div>
            <div className="bg-gray-100 w-full p-8 flex gap-5 rounded-lg">
              <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-fastaxBlue text-white mb-4 flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-6 h-6"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
              </div>
              <div className="flex-grow">
                <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                  Have internet? Good. <br />
                  No internet? Still good.
                </h2>
                <p className="leading-relaxed text-base">
                  All the core accounting features work irrespective of your
                  internet connectivity. Work wouldn't halt due to connectivity
                  issues, ever.
                </p>
              </div>
            </div>
            <div className="bg-gray-100 w-full p-8 flex gap-5 rounded-lg">
              <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-fastaxBlue text-white mb-4 flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-6 h-6"
                  viewBox="0 0 24 24"
                >
                  <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                </svg>
              </div>
              <div className="flex-grow">
                <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                  Time to generate bills. <br />
                  This one's easy!
                </h2>
                <p className="leading-relaxed text-base">
                  Create Sales Bill in a jiffy with pre-made templates. Just
                  fill up the details, select paper size and print.
                </p>
              </div>
            </div>
            <div className="bg-gray-100 w-full p-8 flex gap-5 rounded-lg">
              <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-fastaxBlue text-white mb-4 flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-6 h-6"
                  viewBox="0 0 24 24"
                >
                  <circle cx="6" cy="6" r="3"></circle>
                  <circle cx="6" cy="18" r="3"></circle>
                  <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
                </svg>
              </div>
              <div className="flex-grow">
                <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                  What about E-way Bill? <br />
                  Oh! It's done already.
                </h2>
                <p className="leading-relaxed text-base">
                  We have integrated e-way billing for you. No need to retype
                  reduntant bills information. Just click e-way button and
                  everything happens magically.
                </p>
              </div>
            </div>
            <div className="bg-gray-100 w-full p-8 flex gap-5 rounded-lg">
              <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-fastaxBlue text-white mb-4 flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-6 h-6"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
              </div>
              <div className="flex-grow">
                <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                  What if my computer misbehaves? <br />
                  Auto Backup to the rescue.
                </h2>
                <p className="leading-relaxed text-base">
                  Schedule auto backup as per your requirement and save a copy
                  of your precious data locally or on Google Drive.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="fastax-in-action" className="text-gray-600">
        <div className="container px-5 py-24 mx-auto">
          <div class="flex flex-col">
            <div class="h-1 bg-gray-200 rounded overflow-hidden">
              <div class="w-24 h-full bg-fastaxBlue"></div>
            </div>
            <div class="flex flex-wrap sm:flex-row flex-col py-6 mb-12">
              <h1 class="sm:w-2/5 text-gray-900 font-medium title-font text-2xl mb-2 sm:mb-0">
                Fastax in Action
              </h1>
              <p class="sm:w-3/5 leading-relaxed text-base sm:pl-10 pl-0">
                Let's see how Fastax can make your day to day accounting and
                inventory management work easy and help you get more time to
                grow business.
              </p>
            </div>
          </div>
          <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
            <div class="p-4 md:w-1/3 sm:mb-0 mb-6">
              <iframe
                height="200"
                src="https://www.youtube.com/embed/fq7_Ar4fcoY?si=JpfVDAg-jwqjQunw"
                title="Fastax Demo - Master"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
              <h2 class="text-xl font-medium title-font text-gray-900 mt-5">
                Fastax Master
              </h2>
              <p class="text-base leading-relaxed mt-2">
                Discover how Fastax Master can revolutionize your business
                operations! In this demo video, we guide you through the
                powerful features of Fastax's Master tab, where you can
                effortlessly add or edit items, categories, parties, and more.
              </p>
            </div>
            <div class="p-4 md:w-1/3 sm:mb-0 mb-6">
              <iframe
                height="200"
                src="https://www.youtube.com/embed/n0r5L-8EuUU?si=ooEb5k60_IORYWbN"
                title="Fastax Demo - Purchase Management"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
              <h2 class="text-xl font-medium title-font text-gray-900 mt-5">
                Fastax Purchase Management
              </h2>
              <p class="text-base leading-relaxed mt-2">
                Learn how Fastax streamlines the purchase invoice process,
                saving you time and effort. With features like category-wise
                purchases, HSN reports, monthly purchases, purchase register,
                and more, Fastax is the ultimate solution for all your business
                accounting needs.
              </p>
            </div>
            <div class="p-4 md:w-1/3 sm:mb-0 mb-6">
              <iframe
                height="200"
                src="https://www.youtube.com/embed/tGySpI0-gHA?si=L4EEla4yU_JPu3rV"
                title="Fastax Demo - Sales Management"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
              <h2 class="text-xl font-medium title-font text-gray-900 mt-5">
                Fastax Sales Management
              </h2>
              <p class="text-base leading-relaxed mt-2">
                From navigating the interface to generating the perfect sales
                invoice in Fastax, you'll gain invaluable insights in just a few
                clicks. We can check category-wise sales, HSN reports, monthly
                sales, and a lot more.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="about-us" className="my-gradient-2">
        <div className="container px-5 py-24 mx-auto space-y-3">
          <div className="text-center mb-20">
            <h2 className="sm:text-3xl text-2xl font-medium title-font mb-4">
              About Us - Fastax is an Infokey Brand
            </h2>
            <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-500s">
              Discover the leading company behind Fastax, empowering businesses
              with cutting-edge solutions.
            </p>
            <div className="flex mt-6 justify-center">
              <div className="w-16 h-1 rounded-full bg-white inline-flex"></div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 place-items-center gap-5">
            <div className="bg-white w-fit mx-auto p-5 rounded-md">
              <img
                src={infokeyProducts}
                alt="Fastax is developed by Infokey"
                className="mx-auto"
              />
            </div>
            <div className="space-y-5">
              <p>
                Fastax is proudly developed by{" "}
                <a
                  href={contactConfig.infokeyWebsite + "/about"}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-semibold"
                >
                  Infokey Technology Private Limited
                </a>
                , a leading software-as-a-service provider based in New Delhi,
                India. Infokey specializes in delivering top-notch software
                solutions tailored to meet the diverse needs of businesses. With
                a commitment to innovation and excellence, Infokey offers a
                suite of excellent software products designed to streamline
                operations and boost productivity.
              </p>
              <button className="my-button-style-2 bg-black text-white hover:text-infokeyOrange text-lg">
                <a
                  href={contactConfig.infokeyWebsite}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn More about Infokey
                </a>
              </button>

              <h3 className="text-xl">Other Infokey Products ►►►</h3>
              <div className="space-y-1">
                <h4 className="font-bold text-lg">
                  Yatter AI: ChatGPT & Google Gemini Based Assistant on WhatsApp
                  & Telegram
                </h4>
                <p>
                  Yatter AI is your ultimate AI assistant on WhatsApp &
                  Telegram, powered by ChatGPT-4 Turbo and Google Gemini,
                  providing lightning-fast responses. It is equipped with
                  features like image detection and voice interaction,
                  revolutionizing how you get things done effortlessly and
                  boosting your productivity.
                </p>
                <button className="my-button-style-2 bg-white hover:text-infokeyOrange text-lg">
                  <a
                    href={contactConfig.yatterWebsite}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Explore Yatter AI
                  </a>
                </button>
              </div>
              <div className="space-y-1">
                <h4 className="font-bold text-lg">
                  WaBM: WhatsApp with Superpowers for Businesses
                </h4>
                <p>
                  Boost your business communication & marketing with WaBM, a
                  powerful tool that adds superpowers to WhatsApp for
                  businesses. Transform your messaging and enhance customer
                  interactions effortlessly.
                </p>
                <button className="my-button-style-2 bg-white hover:text-infokeyOrange text-lg">
                  <a
                    href={contactConfig.wabmWebsite}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Explore WaBM
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Pricing />
      <Subscribe />
      <Footer />
    </div>
  );
};

export default Home;
